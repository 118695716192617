
import { user } from '@/states/login'
import useUserFields from './useUserFields'
import { computed, ref } from 'vue'
import { formatDate } from '@/utils/format'
import { Toast } from 'vant'
import { update } from '@/api/user'

export default {
  setup: () => {
    const showBirthdayPicker = ref(false)
    const classes = computed(() => {
      const u = user.value
      return u && u.rsrv1 ? u.rsrv1?.split(',') : []
    })
    const showPicker = ref(false)
    const columns = ['农、林、牧、渔业', '采矿业', '制造业', '电力、热力、燃气及水生产和供应业', '建筑业',
     '交通运输、仓储和邮政业', '信息传输、软件和信息技术服务业', '批发和零售业', '住宿和餐饮业', '金融业'
     , '房地产业', '租赁和商务服务业', '教育', '卫生和社会工作', '公共管理、社会保障和社会组织','其他行业'];
    const onConfirm = (value:string) => {
      user.value.business = value
      showPicker.value = false;
    }
    const showPosPicker = ref(false)
    const posColumns = ['管理岗位', '专技岗位', '工勤岗位'];
    const posOnConfirm = (value:string) => {
      user.value.position = value
      showPosPicker.value = false;
    }
    return {
      showPicker,
      columns,
      onConfirm,
      showPosPicker,
      posColumns,
      posOnConfirm,
      classes,
      showBirthdayPicker,
      ...useUserFields(),
      user,
      userBirthday: new Date(),
      minDate: new Date('1900-01-01'),
      maxDate: new Date(),
      submit: () => {
        update(user.value).then(() => {
          Toast.success('保存成功！')
        })
      },
      onFailed: (err: any) => {
        Toast.fail(err.errors[0].message)
      },
      setBirthday: (d: Date) => {
        user.value.birthday = formatDate(d)
        showBirthdayPicker.value = false
      },
      openBirthdayPicker: () => {
        showBirthdayPicker.value = true
      },
      formatDate: (type: string, date: Date) => formatDate(date)
    }
  }
}
