import { reactive } from 'vue'

export default () => {
  const fields = reactive([
    {
      label: '昵称',
      field: 'username',
      required: true
    },
    // {
    //   label: '班级',
    //   field: 'rsrv1',
    //   readonly: true
    // },
    {
      label: '姓名',
      field: 'realUsername',
      placeholder: '真实姓名',
      required: true,
      rules: [{ required: true, message: '请输入真实姓名' }]
    },
    {
      label: '联系方式',
      field: 'mobile',
      placeholder: '手机号码',
      readonly: true,
      required: true
    },
    {
      label: '行业',
      field: 'business',
      placeholder: '您所处的主要行业',
      required: true,
      rules: [{ required: true, message: '请输入行业' }]
    },
    {
      label: '职位',
      field: 'position',
      placeholder: '目前企业担任的职务/社会身份',
      required: true,
      rules: [{ required: true, message: '请输入职位' }]
    },
    {
      label: '工作年限',
      field: 'workingYear',
      placeholder: '进入社会累计工作年限',
      required: true,
      rules: [{ required: true, message: '请输入工作年限' }]
    },
    {
      label: '生日',
      field: 'birthday',
      placeholder: '写下生日，魔脉陪你一起过',
      readonly: true,
      clickable: true
    },
    {
      label: '收货地址',
      field: 'rsrv2',
      placeholder: '生日有惊喜'
    },
    {
      label: '家乡',
      field: 'hometowm',
      placeholder: '浓厚乡情的地方'
    },
    {
      label: '兴趣爱好',
      field: 'interests',
      placeholder: '三五好友一起玩耍的事儿'
    },
    // {
    //   field: 'resources',
    //   placeholder: '文字描述，控制300字以内',
    //   rows: 2,
    //   autosize: true,
    //   maxlength: 300,
    //   showWordLimit: true
    // },
    // {
    //   field: 'hopes',
    //   placeholder: '文字描述，控制300字以内',
    //   rows: 2,
    //   autosize: true,
    //   maxlength: 300,
    //   showWordLimit: true
    // }
  ])
  return {
    fields
  }
}
